import {
  Box,
  Button,
  ColumnLayout,
  Container,
  FileUpload,
  FormField,
  Header,
  Modal,
  Popover,
  SpaceBetween,
  StatusIndicator
} from '@amzn/awsui-components-react';
import { AgGridReact } from 'ag-grid-react';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { logger, logUserClick } from 'src/analytics/KatalLogger';
import { useAuth } from 'src/app/auth/AuthContextProvider';
import { OperationType } from 'src/constants/generic-constants';
import XptMessages from 'src/constants/xpt-messages';
import { fileUploadI18nStrings } from 'src/i18n-strings/file-upload';
import { ForecastTemplateColumns, ForecastTemplateDataValidationStatus } from 'src/models/ForecastModels';
import { ValidationStatusEntity } from 'src/models/XptGenericModels';
import { selectActiveExpenseTypesForCurrentGroup } from 'src/store/selectors/xPTMapperSelector';
import { RootState } from 'src/store/store';
import { readExcelFileWithHeader } from 'src/utils/file-utils';
import {
  currentBusinessGroup,
  currentBusinessGroupName,
  currentBusinessGroupShortDesc,
  currentDataClassificationId
} from '../businessGroupSelectors';
import { additionalAccounts, ForecastGridFixedFields } from './forecast-utils/ForecastGridConstants';
import { submitForecastData } from './forecast-utils/ForecastTemplateDataSubmission';
import * as DataValidations from './forecast-utils/ForecastTemplateDataValidations';
import {
  getForecastExportFileName,
  getForecastTemplateHeaderInfo,
  prepareForecastTemplateUploadData,
  useEnhancedMasterCorpSegmentDropdowns
} from './forecast-utils/ForecastTemplateUtils';
import ForecastValidationMessages from './forecast-utils/ValidationMessages';
import ForecastTemplateUploadGuidelines from './ForecastTemplateUploadGuidelines';
import { useAgGridExcelExport } from './hooks/useAgGridExcelExport';

const AcceptedFileFormForExcel = {
  ConstraintMessage: 'Upload Excel (.xlsx) file only.',
  FileFormats: '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

interface ForecastTemplateFileUploadProps {
  gridRef: React.RefObject<AgGridReact<any>>;
  showModal: boolean;
  onCancel: () => void;
  onSuccessConfirm: () => void;
}

export const ForecastTemplateFileUpload: React.FC<ForecastTemplateFileUploadProps> = ({ gridRef, showModal, onCancel, onSuccessConfirm }) => {
  const { userAlias } = useAuth();

  const dataClassificationId = useSelector(currentDataClassificationId);

  const { userAccessForCurrentBusinessGroup, xptUsers } = useSelector((state: RootState) => state.xptAccessAndAuthorizationStore);
  const isFinanceAdminOrFinanceOwner = userAccessForCurrentBusinessGroup?.isFinanceAdminOrFinanceOwner || false;
  const isBudgetLeader = userAccessForCurrentBusinessGroup?.isBudgetLeader || false;
  const xptUsersOfCurrentDataClassification = xptUsers.filter((user) => user.is_active && user.data_classification_id === dataClassificationId);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [uploadedFileModifiedData, setUploadedFileModifiedData] = useState<any[]>([]);
  const [isUploadedFileValid, setIsUploadedFileValid] = useState<boolean>(false);
  const [fileValidationStatus, setFileValidationStatus] = useState<ForecastTemplateDataValidationStatus>(DataValidations.INITIAL_VALIDATION_STATUS);
  const [fileSubmitStatus, setFileSubmitStatus] = useState<ValidationStatusEntity>(DataValidations.INITIAL_SUBMIT_STATUS);

  const businessGroup = useSelector(currentBusinessGroup);
  const businessGroupName = useSelector(currentBusinessGroupName);
  const businessGroupShortDesc = useSelector(currentBusinessGroupShortDesc);
  const { currentPlanningCycle } = useSelector((state: RootState) => state.forecastTemplateStore);

  const forecastTemplateCompleteData = useSelector((state: RootState) => state.forecastTemplateStore.forecastTemplateCompleteData);
  const corpSegmentDropdownValues = useSelector((state: RootState) => state.corpSegmentsStore.masterCorpSegmentDropdownValues);

  const masterBusinessSegments = useSelector((state: RootState) => state.corpSegmentsStore.masterBusinessSegments);
  const expenseTypesForCurrentGroup = useSelector(selectActiveExpenseTypesForCurrentGroup);
  const { accountBudgetTypeMapping } = useSelector((state: RootState) => state.xPTMappingStore);
  const [forecastTemplateColumns, setForecastTemplateColumns] = useState<ForecastTemplateColumns>({} as ForecastTemplateColumns);

  const { filterAggregatedColumns } = useAgGridExcelExport({ gridRef, businessGroup, currentPlanningCycle, businessGroupShortDesc, userAlias });

  // Enhance master corporate segment dropdowns with additional accounts
  const enhancedMasterCorpSegmentDropdowns = useEnhancedMasterCorpSegmentDropdowns(
    corpSegmentDropdownValues,
    businessGroupShortDesc,
    additionalAccounts
  );

  // Reset uploaded file and validation status when modal visibility changes
  useEffect(() => {
    resetFileAndValidationStatus();
    setUploadedFile(null);
  }, [showModal]);

  useEffect(() => {
    handleOnFileUpload();
  }, [uploadedFile]);

  useEffect(() => {
    if (businessGroup && currentPlanningCycle) {
      const forecastTemplateColumnDetails: ForecastTemplateColumns = getForecastTemplateHeaderInfo(businessGroup, currentPlanningCycle);
      setForecastTemplateColumns(forecastTemplateColumnDetails);
    }
  }, [businessGroup, currentPlanningCycle]);

  const resetFileAndValidationStatus = () => {
    logger.debug('Resetting file upload state & validations.');
    setFileValidationStatus(DataValidations.INITIAL_VALIDATION_STATUS);
    setUploadedFileModifiedData([]);
    setIsUploadedFileValid(false);
  };

  const handleOnFileUpload = async () => {
    if (uploadedFile && businessGroup && currentPlanningCycle && enhancedMasterCorpSegmentDropdowns) {
      logUserClick(`Forecast Input File upload initiated`, `Forecast Input`, businessGroupName);
      resetFileAndValidationStatus();
      logger.debug(`Step 1: File upload initiated for ${currentPlanningCycle.scenario_year} in ${businessGroupShortDesc}`);
      try {
        const { sheetName } = getForecastExportFileName(businessGroupShortDesc, currentPlanningCycle.scenario_year);
        const { headerRow, fileData } = await readExcelFileWithHeader(uploadedFile, sheetName);
        const filteredHeaderRow = filterAggregatedColumns(headerRow);
        logger.debug('filteredHeaderRow ' + JSON.stringify(filteredHeaderRow));
        logger.info(
          `Step 2: User ${userAlias} uploaded a file for ${currentPlanningCycle.scenario_year} in ${businessGroupShortDesc} with total number of records: ${fileData.length}`
        );
        validateFileData(filteredHeaderRow, fileData);
      } catch (error: any) {
        logger.error('Error while reading the file:', error);
        const errorMessage = error?.message ? error?.message : ForecastValidationMessages.ERROR_READING_FILE;
        setFileValidationStatus({
          ...DataValidations.INITIAL_VALIDATION_STATUS,
          HeadersMatching: {
            colorOverride: 'red',
            validationStatus: 'error',
            validationMessage: ForecastValidationMessages.ERROR_READING_FILE,
            validationDefaultMessage: ForecastValidationMessages.ERROR_READING_FILE,
            validationErrorDetails: [{ message: errorMessage }]
          }
        });
      }
    }
  };

  const validateFileData = async (filteredHeaderRow: string[], fileData: any[]) => {
    if (
      uploadedFile &&
      businessGroup &&
      currentPlanningCycle &&
      enhancedMasterCorpSegmentDropdowns &&
      forecastTemplateColumns &&
      userAccessForCurrentBusinessGroup
    ) {
      try {
        logger.debug(
          `Step 2: Validating file data for ${currentPlanningCycle.scenario_year} in ${businessGroupShortDesc} with total number of records: ${fileData.length}`
        );
        const headersToBe = forecastTemplateColumns.forecastTemplateImportFileHeader;
        logger.debug('headersToBe ' + JSON.stringify(headersToBe));
        const headerValidation = await validateHeadersStep(filteredHeaderRow, headersToBe);

        if (headerValidation.validationStatus === 'error') {
          logger.debug(
            `Step 3: Headers validation failed for ${currentPlanningCycle.scenario_year} in ${businessGroupShortDesc}. No further validations.`
          );
          logUserClick(`Forecast Input File upload - Header Validation failed`, `Forecast Input`, businessGroupName);
          return;
        }

        logger.debug(
          `Step 3: Headers validation passed for ${currentPlanningCycle.scenario_year} in ${businessGroupShortDesc}. Proceeding with further validations.`
        );

        console.time('transformedRows');
        const transformedRows = DataValidations.transformUploadFileDisplayHeaderToModel(fileData, forecastTemplateColumns);
        logger.info(`Transformed Rows with ID fields as it is with length ${transformedRows.length}`);
        console.timeEnd('transformedRows');
        if (transformedRows.length !== fileData.length) {
          logger.error('Mismatch in the number of rows after transformation.');
          logUserClick(`Forecast Input File upload - transformation failed`, `Forecast Input`, businessGroupName);
          setFileValidationStatus((prevState) => ({
            ...prevState,
            HeadersMatching: {
              colorOverride: 'red',
              validationStatus: 'error',
              validationMessage: ForecastValidationMessages.TRANSFORMATION_FAILED,
              validationDefaultMessage: ForecastValidationMessages.TRANSFORMATION_FAILED,
              validationErrorDetails: [{ message: ForecastValidationMessages.TRANSFORMATION_FAILED_DETAILS }]
            }
          }));
          return;
        }

        const budgetOwnerValidation = await DataValidations.validateBudgetOwnersInvolvedInExcel(transformedRows, userAccessForCurrentBusinessGroup);
        if (budgetOwnerValidation.validationStatus === 'error') {
          logger.debug('Unauthorized Budget Owners are being uploaded');
          logUserClick(`Forecast Input File upload - budget owner authorization failed`, `Forecast Input`, businessGroupName);
          setFileValidationStatus((prevState) => ({
            ...prevState,
            BudgetOwnerValidation: {
              colorOverride: 'red',
              validationStatus: 'error',
              validationMessage: ForecastValidationMessages.BUDGET_OWNER_VALIDATION_FAILED,
              validationDefaultMessage: ForecastValidationMessages.BUDGET_OWNER_VALIDATION_DEFAULT,
              validationErrorDetails: budgetOwnerValidation.validationErrorDetails
            }
          }));
          return;
        }

        const checkCCValidation = await DataValidations.validateCCsInvolvedInExcel(transformedRows, userAccessForCurrentBusinessGroup);
        if (checkCCValidation.validationStatus === 'error') {
          logger.debug('Unauthorized Cost Centers are being uploaded');
          logUserClick(`Forecast Input File upload - cost center authorization failed`, `Forecast Input`, businessGroupName);
          setFileValidationStatus((prevState) => ({
            ...prevState,
            CostCenterValidation: {
              colorOverride: 'red',
              validationStatus: 'error',
              validationMessage: ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_FAILED,
              validationDefaultMessage: ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_DEFAULT,
              validationErrorDetails: checkCCValidation.validationErrorDetails
            }
          }));
          return;
        }

        console.time('getModifiedRowsFromExcelFile');
        const modifiedRows = DataValidations.getModifiedRowsFromExcelFile(
          forecastTemplateColumns,
          transformedRows,
          forecastTemplateCompleteData,
          userAlias,
          currentPlanningCycle.scenario_seq_id,
          accountBudgetTypeMapping
        );
        logger.info(`Modified Rows with length ${modifiedRows.length}`);
        console.timeEnd('getModifiedRowsFromExcelFile');

        if (modifiedRows.length === 0) {
          logger.debug('No modified rows found. Skipping further validations.');
          skipValidationSteps();
          return;
        }

        logger.info(`Identified ${modifiedRows.length} modified rows. Proceeding with further validations.`);
        const modifiedTransformed = await validateRemainingSteps(forecastTemplateColumns, modifiedRows);
        setUploadedFileModifiedData(modifiedTransformed);
      } catch (error: any) {
        logger.error('Error during file data validation:', error);
        handleValidationError(error);
      }
    }
  };

  const validateHeadersStep = async (filteredHeaderRow: string[], expectedHeaders: string[]): Promise<ValidationStatusEntity> => {
    const headerValidation = await DataValidations.validateHeaders(filteredHeaderRow, expectedHeaders);

    if (headerValidation.validationStatus === 'error') {
      setIsUploadedFileValid(false);
      skipHeader(setFileValidationStatus, headerValidation);
    } else {
      setIsUploadedFileValid(true);
      setFileValidationStatus((prevState) => ({
        ...prevState,
        HeadersMatching: headerValidation
      }));
    }

    return headerValidation;
  };

  const skipValidationSteps = () => {
    setFileValidationStatus(DataValidations.NO_MODIFIED_ROWS);
  };

  const validateRemainingSteps = async (forecastTemplateColumns: ForecastTemplateColumns, modifiedRows: any[]) => {
    inProgressValidations(setFileValidationStatus);

    const { transformedData: transformedModifiedRows, validationStatus: forecastMonthValidationStatus } =
      await DataValidations.transformForecastMonths(modifiedRows, forecastTemplateColumns);

    const transformedNewRows = transformedModifiedRows.filter((row) => row[ForecastGridFixedFields.IsNewFERow.value]);

    console.time('validateRemainingSteps');
    const validations = await Promise.all([
      DataValidations.transformForecastMonths(modifiedRows, forecastTemplateColumns),
      DataValidations.validateMandatoryFields(transformedModifiedRows, forecastTemplateColumns),
      DataValidations.validateNonEditableFields(
        transformedModifiedRows,
        forecastTemplateColumns,
        forecastTemplateCompleteData,
        isFinanceAdminOrFinanceOwner || isBudgetLeader
      ),
      DataValidations.validateSegments(
        transformedModifiedRows,
        forecastTemplateColumns,
        enhancedMasterCorpSegmentDropdowns,
        masterBusinessSegments,
        expenseTypesForCurrentGroup
      ),
      DataValidations.validateUnAuthorizedRows(transformedModifiedRows, userAlias, isFinanceAdminOrFinanceOwner || isBudgetLeader),
      DataValidations.validateDuplicateRecords(transformedModifiedRows, forecastTemplateColumns),
      DataValidations.validateDuplicateRecordsWhileUpload(transformedNewRows, forecastTemplateColumns, forecastTemplateCompleteData),
      DataValidations.validateBudgetTypeAndAccountCombination(transformedModifiedRows, accountBudgetTypeMapping),
      DataValidations.validateBudgetOwnerAndCostCenterCombination(
        transformedModifiedRows,
        xptUsersOfCurrentDataClassification,
        userAccessForCurrentBusinessGroup!
      )
    ]);

    const errorValidations = validations.filter((validation) => validation.validationStatus === 'error');
    if (errorValidations.length > 0) {
      logUserClick(`Forecast Input File upload - validations failed`, `Forecast Input`, businessGroupName);
    } else {
      logUserClick(`Forecast Input File upload - validations success`, `Forecast Input`, businessGroupName);
    }

    console.timeEnd('validateRemainingSteps');
    setFileValidationStatus({
      HeadersMatching: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.HEADER_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      CostCenterValidation: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.CC_AUTHORIZATION_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      BudgetOwnerValidation: {
        colorOverride: 'green',
        validationStatus: 'success',
        validationMessage: ForecastValidationMessages.BUDGET_OWNER_VALIDATION_SUCCESS,
        validationDefaultMessage: '',
        validationErrorDetails: []
      },
      ForecastMonthValidation: validations[0].validationStatus,
      MandatoryFieldValidation: validations[1],
      NonEditableFieldValidations: validations[2],
      SegmentsValidation: validations[3],
      UnAuthorizedRows: validations[4],
      DuplicateRecordValidation: validations[5],
      RepeatedRecordValidation: validations[6],
      BudgetTypeAccountMappingValidation: validations[7],
      BudgetOwnerCostCenterValidation: validations[8]
    });

    return transformedModifiedRows;
  };

  const handleValidationError = (error: any) => {
    logger.error('Validation error:', error);
    setIsUploadedFileValid(false);
    setFileValidationStatus((prevState) => ({
      ...prevState,
      HeadersMatching: {
        validationStatus: 'error',
        colorOverride: 'red',
        validationMessage: ForecastValidationMessages.VALIDATION_ERROR,
        validationDefaultMessage: ForecastValidationMessages.VALIDATION_ERROR,
        validationErrorDetails: [error.message]
      }
    }));
  };

  const isAllValidationsPassed = () => {
    return (
      fileValidationStatus.HeadersMatching.validationStatus === 'success' &&
      fileValidationStatus.CostCenterValidation.validationStatus === 'success' &&
      fileValidationStatus.ForecastMonthValidation.validationStatus === 'success' &&
      fileValidationStatus.MandatoryFieldValidation.validationStatus === 'success' &&
      fileValidationStatus.NonEditableFieldValidations.validationStatus === 'success' &&
      fileValidationStatus.SegmentsValidation.validationStatus === 'success' &&
      fileValidationStatus.UnAuthorizedRows.validationStatus === 'success' &&
      fileValidationStatus.DuplicateRecordValidation.validationStatus === 'success' &&
      fileValidationStatus.RepeatedRecordValidation.validationStatus === 'success' &&
      fileValidationStatus.BudgetTypeAccountMappingValidation.validationStatus === 'success' &&
      fileValidationStatus.BudgetOwnerCostCenterValidation.validationStatus === 'success'
    );
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (businessGroup && currentPlanningCycle) {
      const forecastTemplateDataForUpload = prepareForecastTemplateUploadData(uploadedFileModifiedData, currentPlanningCycle, businessGroup);
      setFileSubmitStatus({
        validationMessage: 'Request in progress',
        validationStatus: 'loading',
        colorOverride: 'blue',
        validationDefaultMessage: '',
        validationErrorDetails: []
      });

      try {
        await submitForecastData({
          userAlias,
          businessGroup,
          currentPlanningCycle: currentPlanningCycle,
          forecastTemplateDataForUpload,
          submitOperationType: OperationType.FILE_UPLOAD
        });
        logUserClick(`Forecast Input File upload - upload success`, `Forecast Input`, businessGroupName);
        setFileSubmitStatus({
          validationMessage: XptMessages.FORECAST_UPDATE_SUCCESS(currentPlanningCycle.scenario_year),
          validationStatus: 'success',
          colorOverride: 'green',
          validationDefaultMessage: '',
          validationErrorDetails: []
        });
      } catch (error: any) {
        logger.error('Error during forecast data submission:', error);
        setFileSubmitStatus({
          validationMessage: XptMessages.FORECAST_UPDATE_FAILED,
          validationStatus: 'error',
          colorOverride: 'red',
          validationDefaultMessage: XptMessages.FORECAST_UPDATE_FAILED,
          validationErrorDetails: [error.message]
        });
      }
    } else {
      logger.error('Business group or planning cycle data is missing.');
    }
  };

  return (
    <Modal
      onDismiss={(e) => {
        if (fileSubmitStatus.validationStatus !== 'loading') onCancel();
      }}
      visible={showModal}
      size="large"
      footer={
        <Box>
          <Box float="left">
            {fileSubmitStatus.validationMessage !== 'Not Initiated' && (
              <StatusIndicator type={fileSubmitStatus.validationStatus} colorOverride={fileSubmitStatus.colorOverride}>
                {fileSubmitStatus.validationMessage}
              </StatusIndicator>
            )}
          </Box>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              {fileSubmitStatus.validationStatus !== 'success' && (
                <>
                  <Button variant="link" onClick={handleCancel} disabled={fileSubmitStatus.validationStatus === 'loading'}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={!isAllValidationsPassed() || fileSubmitStatus.validationStatus === 'loading'}
                  >
                    Submit
                  </Button>
                </>
              )}
              {fileSubmitStatus.validationStatus === 'success' && (
                <Button variant="primary" onClick={onSuccessConfirm}>
                  Close
                </Button>
              )}
            </SpaceBetween>
          </Box>
        </Box>
      }
      header={
        <Header variant="h3">{`Upload Forecast Template for ${currentPlanningCycle?.scenario.scenario_name} (${currentPlanningCycle?.scenario_year})`}</Header>
      }
    >
      <ColumnLayout columns={2}>
        <FormField label="" description="">
          {fileSubmitStatus.validationStatus !== 'success' && (
            <FileUpload
              onChange={({ detail }) => setUploadedFile(detail.value[0])}
              value={uploadedFile ? [uploadedFile] : []}
              i18nStrings={fileUploadI18nStrings()}
              invalid={!isUploadedFileValid}
              accept={AcceptedFileFormForExcel.FileFormats}
              constraintText={AcceptedFileFormForExcel.ConstraintMessage}
              ariaRequired
              multiple={false}
              showFileLastModified
              showFileSize
              showFileThumbnail
              tokenLimit={1}
            />
          )}
          <Box padding={{ top: 's' }}>
            <SpaceBetween size="m" direction="vertical">
              {Object.keys(fileValidationStatus).map((key, index) => {
                const validationStatus: ValidationStatusEntity = fileValidationStatus[key as keyof ForecastTemplateDataValidationStatus];
                return (
                  <Fragment key={index}>
                    {validationStatus.validationStatus !== 'error' && (
                      <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                        {validationStatus.validationMessage}
                      </StatusIndicator>
                    )}
                    {validationStatus.validationStatus === 'error' && (
                      <Popover
                        header={validationStatus.validationDefaultMessage}
                        size="large"
                        content={
                          <Box>
                            <ul>
                              {validationStatus.validationErrorDetails.map((details, index) => (
                                <li key={index}>{details.message}</li>
                              ))}
                            </ul>
                          </Box>
                        }
                      >
                        <StatusIndicator key={index} type={validationStatus.validationStatus} colorOverride={validationStatus.colorOverride}>
                          {validationStatus.validationMessage}
                        </StatusIndicator>
                      </Popover>
                    )}
                  </Fragment>
                );
              })}
            </SpaceBetween>
          </Box>
        </FormField>

        {currentPlanningCycle && forecastTemplateColumns && (
          <Container variant="stacked">
            <ForecastTemplateUploadGuidelines selectedPlanningCycle={currentPlanningCycle} forecastTemplateColumns={forecastTemplateColumns} />
          </Container>
        )}
      </ColumnLayout>
    </Modal>
  );
};

const skipHeader = (
  setFileValidationStatus: React.Dispatch<React.SetStateAction<ForecastTemplateDataValidationStatus>>,
  headerValidation: ValidationStatusEntity
) => {
  setFileValidationStatus((prevState) => ({
    ...prevState,
    HeadersMatching: {
      ...headerValidation,
      validationStatus: 'error'
    },
    ForecastMonthValidation: {
      ...prevState.ForecastMonthValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    MandatoryFieldValidation: {
      ...prevState.MandatoryFieldValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    NonEditableFieldValidations: {
      ...prevState.NonEditableFieldValidations,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    UnAuthorizedRows: {
      ...prevState.UnAuthorizedRows,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    SegmentsValidation: {
      ...prevState.SegmentsValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    DuplicateRecordValidation: {
      ...prevState.DuplicateRecordValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    },
    RepeatedRecordValidation: {
      ...prevState.RepeatedRecordValidation,
      validationStatus: 'error',
      validationMessage: ForecastValidationMessages.SKIPPED_DUE_TO_HEADER_FAILURE
    }
  }));
};

const inProgressValidations = (setFileValidationStatus: React.Dispatch<React.SetStateAction<ForecastTemplateDataValidationStatus>>) => {
  setFileValidationStatus((prevState) => ({
    ...prevState,
    HeadersMatching: {
      ...prevState.HeadersMatching,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.HEADER_VALIDATION_N_PROGRESS
    },
    ForecastMonthValidation: {
      ...prevState.ForecastMonthValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.FORECAST_MONTH_DATA_VALIDATION_IN_PROGRESS
    },
    MandatoryFieldValidation: {
      ...prevState.MandatoryFieldValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.MANDATORY_FIELDS_VALIDATION_IN_PROGRESS
    },
    NonEditableFieldValidations: {
      ...prevState.NonEditableFieldValidations,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.NON_EDITABLE_FIELD_VALIDATION_IN_PROGRESS
    },
    UnAuthorizedRows: {
      ...prevState.UnAuthorizedRows,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.UNAUTHORIZED_ROWS_VALIDATION_IN_PROGRESS
    },
    SegmentsValidation: {
      ...prevState.SegmentsValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.SEGMENTS_VALIDATION_IN_PROGRESS
    },
    DuplicateRecordValidation: {
      ...prevState.DuplicateRecordValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.DUPLICATE_RECORDS_VALIDATION_IN_PROGRESS
    },
    RepeatedRecordValidation: {
      ...prevState.RepeatedRecordValidation,
      validationStatus: 'in-progress',
      validationMessage: ForecastValidationMessages.EXISTING_RECORDS_VALIDATION_IN_PROGRESS
    }
  }));
};
